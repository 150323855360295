import React from 'react'
import Img from 'gatsby-image'
import styled from 'styled-components'
import { className } from '../../utils/className'

const CustomView = (props) => {
  const {
    getStyles,
    isFullscreen,
    isModal,
    modalProps: { onClose },
  } = props
  return (
    <div
      onClick={onClose}
      className={className('view', { isFullscreen, isModal })}
      css={getStyles('view', props)}
    >
      <ViewImage
        className={className('view-image', {
          isFullscreen,
          isModal,
        })}
        imgStyle={{
          objectFit: 'contain',
        }}
        placeholderStyle={{
          objectFit: 'contain',
        }}
        fluid={props.data}
      />
    </div>
  )
}

export default CustomView

const ViewImage = styled(Img)`
  max-height: 100vh;
  height: auto;
  user-select: none;
  max-width: 1200px;
  margin: auto;
`
