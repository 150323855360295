import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Grid from '../../components/grid/grid'

const MastGrid = () => {
  const data = useStaticQuery(graphql`
    query {
      images: allFile(
        filter: {
          relativeDirectory: { eq: "posts/2020/mast-nazron-se/images" }
        }
      ) {
        edges {
          node {
            childImageSharp {
              blur: fluid(maxWidth: 1200, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
              traced: fluid(maxWidth: 1200, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
              fluid(maxWidth: 1200, quality: 100) {
                src
              }
            }
            name
            relativeDirectory
          }
        }
      }
      videos: allContentfulAsset(
        filter: { title: { regex: "/^mast-nazron-se/" } }
      ) {
        edges {
          node {
            id
            title
            file {
              url
              fileName
              contentType
            }
            description
          }
        }
      }
    }
  `)
  return <Grid data={data} />
}

export default MastGrid
