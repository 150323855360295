import React, { useState } from 'react'
import Img from 'gatsby-image'
import styled from 'styled-components'
import Carousel, { Modal, ModalGateway } from 'react-images'
import View from './view'

const GalleryGrid = ({ data }) => {
  const [visible, setVisible] = useState(false)
  const [slide, setSlide] = useState(0)

  const {
    images: { edges: images },
    videos: { edges: videos },
  } = data
  const imageList = images.filter((image) => {
    if (image.node.name.includes('video-poster')) return null
    return image
  })
  const galleryImages = imageList.map(
    (image) => image.node.childImageSharp.blur
  )

  return (
    <>
      <GridContainer>
        {videos.map((video) => {
          let videoPoster = ''
          images.forEach((image) => {
            if (image.node.name.startsWith(video.node.title)) {
              videoPoster = image.node.childImageSharp.fluid.src
            }
          })
          return (
            <VideoContainer key={video.node.title}>
              <GridVideo
                controls
                poster={videoPoster}
                disablePictureInPicture
                controlsList="nodownload"
              >
                <source src={video.node.file.url} type="video/mp4" />
              </GridVideo>
            </VideoContainer>
          )
        })}
        {imageList.map((image, index) => {
          return (
            <ImageContainer
              onClick={() => {
                setSlide(index)
                setVisible(!visible)
              }}
              key={image.node.name}
            >
              <Image fluid={image.node.childImageSharp.traced} />
            </ImageContainer>
          )
        })}
      </GridContainer>
      <ModalGateway>
        {visible ? (
          <Modal onClose={() => setVisible(false)}>
            <Carousel
              views={galleryImages}
              hideControlsWhenIdle={5000}
              currentIndex={slide}
              components={{
                View,
              }}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </>
  )
}

export default GalleryGrid

const GridContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  border: 1px solid;
  margin: 5px 0;
  > div {
    @media screen and (max-width: 600px) {
      flex: 1 1 calc(100% - 1rem);
    }
    @media screen and (min-width: 601px) and (max-width: 900px) {
      flex: 1 1 calc(50% - 1rem);
    }
    flex: 1 1 calc(33% - 1rem);
  }
  > div:nth-child(6) {
    @media (min-width: 601px) and (max-width: 900px) {
      flex: 1 1 calc(100% - 1rem);
    }
    flex: 1 1 calc(50% - 1rem);
  }
`
const VideoContainer = styled.div`
  margin: 1px;
  background-color: black;
  @media screen and (min-width: 600px) {
    flex: 1 1 calc(55% - 1rem) !important;
    transition: all 1s ease;
  }
`
const GridVideo = styled.video`
  width: 100%;
  display: block;
  height: 100%;
  :focus {
    outline: none;
  }
`
const ImageContainer = styled.div`
  margin: 1px;
  :hover {
    cursor: pointer;
    opacity: 0.8;
    @media (hover: none) {
      opacity: 1;
    }
  }
`
const Image = styled(Img)`
  height: 100%;
  max-height: 500px;
`
